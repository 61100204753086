import axios from "axios"

import Swal from "sweetalert2"

import { getToken, logout } from "./auth"
import { dataToBlob } from "../helpers/format"

export const frontendEnvironment = () => {
  let url = window.location.hostname.split(".")[0]

  const qa = "https://canteiro-obrasqa.direcional.com.br"
  const prod = "https://canteiro-obras.direcional.com.br"
  const dev = "https://canteiro-obrasdev.direcional.com.br"
  const stage = "https://canteiro-obrasstage.direcional.com.br"
  const qaTestes = "https://onboardingtestes.direcional.com.br/"

  if (url === "canteiro-obrasqa") return qa

  if (url === "canteiro-obrasstage") return stage

  if (url === "onboardingtestes") return qaTestes

  if (url === "canteiro-obras") return prod

  return dev
}

const backendEnvironment = () => {
  let url = window.location.hostname.split(".")[0]

  const prod = "https://api.direcional.com.br/onboardingCanteiro/v1"
  const qa = "https://apihmg.direcional.com.br/onboardingCanteiro/v1"
  const dev = "https://apidev.direcional.com.br/onboardingCanteiro/v1/"
  const stage = "https://apistage.direcional.com.br/onboardingCanteiro/v1"
  const qaTestes = "https://apitest.direcional.com.br/onboardingCanteiro/v1"

  if (url === "canteiro-obrasqa") return qa

  if (url === "canteiro-obrasstage") return stage

  if (url === "onboardingtestes") return qaTestes

  if (url === "canteiro-obras") return prod

  return dev
}

const api = axios.create({
  baseURL: backendEnvironment(),
  headers: {
    client_id: process.env.REACT_APP_CLIENT_ID,
  },
})

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.common["access_token"] = `${token}`
  }
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // Sem internet
    if (!error.response) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Problemas de conexão</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        text: "Parece que você está sem internet, verique sua conexão e tente novamente.",
      })
      return Promise.reject(error)
    }
    // Não autenticado
    if (error.response?.status === 401) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Acesso expirado</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        NOVO ACESSO        ",
        text: "Faça novamente o acesso a sua conta e ative um novo código de verificação.",
      }).then(() => {
        logout()
        window.location = "/"
      })
      return Promise.reject(error)
    }
    // Erro no servidor
    if (error.response?.status >= 500 && error.response?.status < 600) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Falha de solicitação</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        text: "O servidor encontrou um erro temporário e não pôde concluir sua solicitação, tente novamente.",
      })
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export const UserLogin = async (userId, password, tokenCloud) => {
  try {
    let response = await api.post("/Autenticacao/LoginUsuario", {
      idUsuario: userId,
      senha: password,
      captchaCode: tokenCloud,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    if (!error.response) {
      return "networkError"
    } else {
      return error.response?.data
    }
  }
}

export const UserValidate = async (userId, password, code) => {
  try {
    let response = await api.post("/Autenticacao/ValidaUsuario", {
      senha: password,
      idUsuario: userId,
      codigoVerificacao: code,
      aceiteTermos: [0],
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const TokenValidation = async deCode => {
  try {
    let response = await api.get("/Autenticacao/ValidaToken")

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const TakeVacancy = async (deCode, vacancyNumber, cpf, employerName) => {
  try {
    let response = await api.put("/Vaga/AssumirVaga", {
      cpf: cpf,
      idExterno: deCode,
      desativaValidacao: false,
      nrChamado: vacancyNumber,
      nomeEmpregado: employerName,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const DeleteVacancy = async vacancyNumber => {
  try {
    let response = await api.put("/Vaga/CancelarVagaOnboarding", {
      nrChamado: vacancyNumber,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const ClearVacancy = async vacancyNumber => {
  try {
    let response = await api.delete("/Vaga/ApagarDadosVaga",
      {
        data: {
          nrChamado: vacancyNumber,
        }
      }
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const DeleteDocument = async documentId => {
  try {
    let response = await api.delete(`Documento/DeleteDocumento?id=${documentId}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const GetCandidate = async vacancyNumber => {
  try {
    let response = await api.get(`/Vaga/BuscaCandidatoVaga?nrChamado=${vacancyNumber}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const GetCostCenters = async deCode => {
  try {
    let response = await api.get(`/Vaga/ListarObras`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const GetVacanciesByCostCenter = async (deCode, costCenterDescription) => {
  try {
    let response = await api.get(
      `Vaga/ListarVagasPorObra?descObra=${costCenterDescription}`
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const GetVacancyDetails = async vacancyNumber => {
  try {
    let response = await api.get(`/Vaga/DetalhesVaga?nrChamado=${vacancyNumber}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data;
  }
}

export const GetVacancyDocuments = async vacancyNumber => {
  try {
    let response = await api.get(`/Documento/DocumentosOnboarding?nrChamado=${vacancyNumber}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const GetDocumentsQuantityByVacancy = async vacanciesNumbers => {
  try {
    let response = await api.get(
      `/Documento/QtdeDocumentoByVagas?listaNrChamado=${vacanciesNumbers}`
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const SendToCentral = async vacancyNumber => {
  try {
    let response = await api.post("/Documento/EnviaDocumentosFluig", {
      nrChamado: vacancyNumber,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const UploadDocument = async (images, documentId) => {
  let formData = new FormData()

  formData.append("documentoListaId", documentId)

  const verifyExtension = file => {
    if (typeof file === "string") {
      formData.append("documentosAnexados", dataToBlob(file), "imagem.pdf")
    } else {
      formData.append("documentosAnexados", file, "imagem.jpg")
    }
  }

  images.forEach(image => {
    verifyExtension(image)
  })

  try {
    let response = await api.post(`Documento/PostDocumento`, formData)

    if (response.data) return response
  } catch (error) {
    return error.response?.data
  }
}

export const receiveTermsLink = async () => {
  try {
    let response = await api.get("api/Termo")

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
}

export const verifyLink = async (key) => {

  let response = await api.get("/api/LinkVaga/VerifyLink?key=" + key)
  return response.data

}

export const vacancyLinkLogin = async (keyLink, keyLogin) => {

  try {
    let response = await api.post("/Autenticacao/LoginLinkVaga",
      {
        keyLogin,
        keyLink
      })
    return response.data.session
  } catch (err) {
    return null
  }

}

export const getEmployeeData = async (employeeCPF) => {
  try {
    const response = await api.get(`/api/LinkVaga/GetDadosEmpregado?cpf=${employeeCPF}`);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error) {
    return error.response?.data;
  }
};


export const sendEmployeeData = async (data) => {
  try {
    const response = await api.post(
      "/api/LinkVaga/EnviaDadosEmpregado",
      JSON.stringify(data, null, 0),
      {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'text/plain',
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error) {
    console.error('Erro ao enviar dados:', error);
    return error.response?.data;
  }
};

/* export const TakeVacancy = async (deCode, vacancyNumber, cpf, employerName) => {
  try {
    let response = await api.put("/Vaga/AssumirVaga", {
      cpf: cpf,
      idExterno: deCode,
      desativaValidacao: false,
      nrChamado: vacancyNumber,
      nomeEmpregado: employerName,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error) {
    return error.response?.data
  }
} */

export const SendLink = async (data) => {
  try {
    const response = await api.post(
      "/api/LinkVaga/SendLink",
      JSON.stringify(data, null, 0),
      {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'text/plain',
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  } catch (error) {
    console.error('Erro ao enviar link:', error);
    return error.response?.data;
  }
}

export const deleteValeTransporte = async (cpf, linha) => {
  try {
    const response = await api.delete(`/api/LinkVaga/ExcluirValeTransporte?cpf=${cpf}&linha=${linha}`, {
      headers: {
        'accept': 'text/plain',
      },
    });
    return response;
  } catch (error) {
    console.error('Erro ao excluir vale transporte:', error);
    return { status: error.response?.status, data: error.response?.data };
  }
};

export const deleteDependente = async (cpf) => {
  try {
    const response = await api.delete(`/api/LinkVaga/ExcluirDependente?cpf=${cpf}`, {
      headers: {
        'accept': 'text/plain',
      },
    });
    return response;
  } catch (error) {
    console.error('Erro ao excluir dependente:', error);
    return { status: error.response?.status, data: error.response?.data };
  }
};

export const getEstadoNatal = async () => {
  try {
    const cachedEstados = localStorage.getItem("estados");
    if (cachedEstados) {
      return JSON.parse(cachedEstados);
    }

    const response = await api.get("/api/LinkVaga/GetEstadoNatal");
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem("estados", JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    return error.response?.data;
  }
};

export const getCidadeNatal = async (siglaEstado) => {
  try {
    const cachedCidades = localStorage.getItem(`cidades_${siglaEstado}`);
    if (cachedCidades) {
      return JSON.parse(cachedCidades);
    }

    const response = await api.get(`/api/LinkVaga/GetCidadeNatal?siglaEstado=${siglaEstado}`);
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem(`cidades_${siglaEstado}`, JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    return error.response?.data;
  }
};

export const getNacionalidadeEstrangeira = async () => {
  try {
    const cachedNacionalidades = sessionStorage.getItem("nacionalidades");
    if (cachedNacionalidades) {
      return JSON.parse(cachedNacionalidades);
    }
    const response = await api.get("/api/LinkVaga/GetPaisNatal");
    if (response.status >= 200 && response.status < 300) {
      sessionStorage.setItem("nacionalidades", JSON.stringify(response.data));
      return response.data;
    } else {
      console.error("Erro na resposta da API", response);
      throw new Error("Erro ao obter dados da API");
    }
  } catch (error) {
    console.error("Erro no processamento", error);
    return error.response?.data || "Erro desconhecido";
  }
};


export const generateSensediaToken = async (keyLink, code, session) => {

  const body = session === null ? { keyLink, code } : { keyLink, code, session }
  let response = await api.post("/Autenticacao/GenerateTokenSensedia", body)
  return response.data.access_token;

}

export default api
